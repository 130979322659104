<template>
  <div class="welcome">
    <div class="icon donut" @click="goToHome" />
    <div v-if="isMobile" class="header-mobile">
      <div class="back-icon" />
      <div class="back-douglas" @click="goToDouglas"> {{ backText }}</div>
    </div>
    <div class="welcome-center-box">
      <div class="welcome-text-container">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="welcome-title" v-html="$t('welcome_title')" />
        <div class="welcome-subtitle">{{ $t('welcome_subtitle') }}</div>
        <div class="welcome-text">{{ $t('welcome_question') }}</div>
      </div>
      <div class="welcome-action-buttons">
        <button class="btn btn--primary" :class="{selected:qsForMe===true}" @click="nextForMe">{{ $t("welcome_for_me") }}</button>
        <button class="btn btn--primary" :class="{selected:qsForMe===false}" @click="nextForGift">{{ $t("welcome_for_gift") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import QueryStringMixin from '../mixins/QueryStringMixin'
import TrackingMixin from '../mixins/TrackingMixin'
import { ROUTER_NAMES, STATIC_PATH_SETTING } from '../settings'
export default {
  mixins: [QueryStringMixin, TrackingMixin],
  computed: {
    backText() {
      return this.$t('back_to_douglas')
    },
  },
  mounted() {
    this.eventTracker.emitEvent('VIEW_OPENED', { type: 'LANDING_PAGE' })
    return this.douglasTrack()
  },
  methods: {
    nextForMe() {
      clearTimeout(this.timer)
      this.qsForMe = true
      this.eventTracker.emitEvent('ADD_TO_PROFILE', { type: 'FOR_WHOM', id: 'ME', resultingProfile: { forWhom: 'ME' } })
      this.timer = setTimeout(() => {
        this.$router.push({
          name: ROUTER_NAMES.SEARCH_FRAGRANCE,
          query: { ...this.$route.query },
        })
      }, 500)
    },
    nextForGift() {
      clearTimeout(this.timer)
      this.qsForMe = false
      this.eventTracker.emitEvent('ADD_TO_PROFILE', { type: 'FOR_WHOM', id: 'THEM', resultingProfile: { forWhom: 'THEM' } })
      this.timer = setTimeout(() => {
        this.$router.push({
          name: ROUTER_NAMES.SEARCH_FRAGRANCE,
          query: { ...this.$route.query },
        })
      }, 500)
    },
    goToHome() {
      if(this.$route.name !== ROUTER_NAMES.WELCOME)
      this.$routerPush({ name: ROUTER_NAMES.WELCOME })
    },
    goToDouglas() {
      window.open(STATIC_PATH_SETTING.DOUGLAS_WELCOME, '_self')
    },
  },
}
</script>

<style lang="stylus" scoped>
.welcome
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%

  .mobile &
    position: relative
    display: block
    flex-direction: column

    .icon
      align-self: flex-start
      margin-left: vw(10px)

      &.donut
        m-icon("donut", 36)

        &:hover
          cursor: pointer

.welcome-center-box
  margin: auto
  max-width: 50%

  .mobile &
    position: absolute
    top: 50%
    left: 50%
    max-width: 90%
    width: 100%
    transform: translate(-50%, -50%)

.header-mobile
  display: flex
  align-self: flex-start
  align-items: center
  margin-top: vw(20px)
  margin-left: vw(10px)

  .back-icon
    m-icon("arrow-right", 20)
    margin-right: vw(5px)
    transform: rotate(180deg)

  .back-douglas
    m-font-size(16, 20)
    margin-top: -0.2em
    text-decoration: underline

    &:hover
      cursor: pointer

.welcome-text-container
  text-align: center

  .mobile &
    text-align: left

.welcome-title
  m-font-size(30, 44)
  margin-bottom: vw(25px)
  max-width: vw(650px)
  font-weight: 700
  font-family: "Avenir"

  .mobile &
    m-font-size(26, 33)
    font-family: "AvenirNext"

.welcome-subtitle
  m-font-size(16, 26)
  margin-bottom: vw(20px)
  max-width: vw(600px)

  .mobile &
    m-font-size(20, 30)
    margin-bottom: vw(30px)

.welcome-text
  m-font-size(16, 26)
  margin-bottom: vw(50px)

  .mobile &
    m-font-size(20, 24)

  &:empty
    display: none

.welcome-action-buttons
  display: flex
  justify-content: center
  align-items: center

  .mobile &
    flex-direction: column

  >>> .btn
    width: vw(300px)
    height: vw(60px)
    border-color: #E6E6E6
    background-color: #fff
    color: #000
    transition: all 0.5s

    &.selected
      border-color: $primary-theme-color-dark
      background-color: $primary-theme-color-dark
      color: #fff

    &:active
      border-color: $primary-theme-color
      background-color: $primary-theme-color
      color: #fff

    .mobile &
      width: 100%

      &:hover,
      &:active
        border-color: $primary-theme-color-dark
        background-color: $primary-theme-color-dark
        color: #fff

      + .btn
        margin-top: vw(20px)
        margin-left: 0
</style>